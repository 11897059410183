package com.dawarchive.sections

import androidx.compose.runtime.*
import com.dawarchive.components.animatedBar
import com.dawarchive.components.sectionTitle
import com.dawarchive.models.RecentUpdates
import com.dawarchive.models.Section
import com.dawarchive.models.Theme
import com.dawarchive.util.Constants.SECTION_WIDTH
import com.dawarchive.util.observeViewportEntered
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun updatesSection() {
    Box(
        modifier = Modifier
            .id(Section.UPDATES.id)
            .fillMaxWidth()
            .backgroundColor(Theme.LighterGray.rgb)
            .padding(topBottom = 74.px),
        contentAlignment = Alignment.Center
    ) {
        updatesContentBox()
    }
}

@Composable
fun updatesContentBox() {
    Box(
        modifier = Modifier
            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.Center
    ){
        updates()
    }
}

@Composable
fun updates() {
    var viewportEntered by remember { mutableStateOf(false) }
    val animatedPercentage = remember { mutableStateListOf(0, 0, 0, 0, 0) }

    val breakpoint = rememberBreakpoint()

    observeViewportEntered(
        sectionId = Section.UPDATES.id,
        distanceFromTop = 300.0,
        onViewportEntered = {
            viewportEntered = true
        }
    )

    Column(
        modifier = Modifier.fillMaxWidth(
            if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent
        ),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        sectionTitle(
            section = Section.UPDATES,
            modifier = Modifier.margin(topBottom = 45.px)
        )
        RecentUpdates.values().forEach { updates ->
            animatedBar(
                title = updates.title,
                index = updates.ordinal,
                percentage = if (viewportEntered) updates.percentage else 0.percent,
                animatedPercentage = if (viewportEntered) animatedPercentage[updates.ordinal] else 0
            )
        }
    }
}