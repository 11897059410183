package com.dawarchive.sections

import androidx.compose.runtime.Composable
import com.dawarchive.models.Section
import com.dawarchive.styles.MainLogoStyle
import com.dawarchive.util.Constants
import com.dawarchive.util.Res
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px

@Composable
fun googlePlaySection() {
    Box(
        modifier = Modifier
            .id(Section.GOOGLE_PLAY.id)
            .maxWidth(Constants.SECTION_WIDTH.px)
            .padding(top = 100.px)
            .minHeight(250.px),
        contentAlignment = Alignment.TopCenter
    ) {
        googlePlayLogo()
    }
}

@Composable
fun googlePlayLogo(
) {

    Column(
        modifier = Modifier,
        verticalArrangement = Arrangement.SpaceEvenly,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Link(
            path = Constants.GOGGLE_PLAY_LINK,
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ) {
            Image(
                modifier = MainLogoStyle.toModifier(),
                src = Res.Icon.google_play_logo,
                desc = "Google Play"
            )
        }
    }
}
