package com.dawarchive.sections

import androidx.compose.runtime.Composable
import com.dawarchive.models.Section
import com.dawarchive.models.Theme
import com.dawarchive.util.Constants
import com.dawarchive.util.Constants.FONT_FAMILY
import com.dawarchive.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun mainSection() {
    Box(
        modifier = Modifier
            .id(Section.HOME.id)
            .fillMaxSize()
            .backgroundColor(Theme.LighterGray.rgb)
            .padding(leftRight = 12.px, bottom = 48.px, top = 40.px),
        contentAlignment = Alignment.Center
    ) {
        mainContent()
    }
}

@Composable
fun mainContent() {
    val breakpoint = rememberBreakpoint()

        Column(
            modifier = Modifier,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SimpleGrid(
                modifier = Modifier,
                numColumns = numColumns(base = 1, md = 2)
            ) {
                mainImage(breakpoint = breakpoint)
                mainText(breakpoint = breakpoint)
            }
        }
}

@Composable
fun mainText(breakpoint: Breakpoint) {
    Column (
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = if (breakpoint >= Breakpoint.MD) Alignment.Start else Alignment.CenterHorizontally
            ){
        P(
            attrs = Modifier
                .margin(top = 12.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 64.px else 48.px)
                .fontWeight(FontWeight.ExtraBlack)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constants.DAW_NAME)
        }
        P(
            attrs = Modifier
                .margin(bottom = 12.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 64.px else 48.px)
                .fontWeight(FontWeight.ExtraBlack)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constants.ARCHIVE_NAME)
        }
        P(
            attrs = Modifier
                .margin(topBottom = 0.px)
                .padding(top = 25.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 28.px else 22.px)
                .fontWeight(FontWeight.Black)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constants.APP_PURPOSE)
        }
    }
}


@Composable
fun  mainImage(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .fillMaxSize(100.percent),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier
                .maxSize(if (breakpoint >= Breakpoint.MD) 200.px else 160.px )
                .clip(Circle()),
            src = Res.Icon.main_logo,
            desc = "Main Image"
        )
    }
}