package com.dawarchive.components

import androidx.compose.runtime.Composable
import com.dawarchive.models.ScreenShot
import com.dawarchive.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun screenShotCard(
    modifier: Modifier = Modifier,
    screenShot: ScreenShot,
    breakpoint: Breakpoint
) {

    Row(modifier = modifier.maxWidth(500.px)) {
        Image(
            modifier = Modifier
                .margin(right = 20.px)
                .maxWidth(if (breakpoint >= Breakpoint.MD) 180.px else 140.px)
                .borderRadius(
                    topLeft = 8.px,
                    topRight = 8.px,
                    bottomLeft = 8.px,
                    bottomRight = 8.px
                ),
            src = screenShot.image,
            desc = "Screenshot"
        )
        Column {
            P(
                attrs = Modifier
                    .fillMaxWidth()
                    .margin(topBottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize( if (breakpoint >= Breakpoint.MD) 22.px else
                        20.px)
                    .fontWeight(FontWeight.Bold)
                    .toAttrs()
            ) {
                Text(screenShot.title)
            }
            P(
                attrs = Modifier
                    .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 75.percent )
                    .margin(top = 12.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize( if (breakpoint >= Breakpoint.MD) 16.px else
                        14.px)
                    .fontWeight(FontWeight.Thin)
                    .toAttrs()
            ) {
                Text(screenShot.desc)
            }
        }
    }
}