package com.dawarchive.components

import androidx.compose.runtime.Composable
import com.dawarchive.models.Theme
import com.dawarchive.styles.ConfirmTickStyle
import com.dawarchive.util.Constants.FONT_FAMILY
import com.dawarchive.util.Res
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun animatedBar(
    title: String,
    index: Int,
    progressBarHeight: CSSSizeValue<CSSUnit.px> = 2.px,
    percentage: CSSSizeValue<CSSUnit.percent> = 50.percent,
    animatedPercentage: Int
) {

    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .margin(bottom = 10.px)
            .padding(topBottom = 5.px)
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 5.px),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            P(
                attrs = Modifier
                    .margin(right = 100.px)
                    .padding(top = 40.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if (breakpoint >= Breakpoint.MD) 18.px else 14.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Black.rgb)
                    .toAttrs()
            ) {
                Text(title)
            }

            Box(
                modifier = Modifier
                    .id("tickBox")
                    .padding(top = 22.px)
                    .margin(bottom = 0.px)
            ) {
                P(
                    attrs = ConfirmTickStyle.toModifier()
                        .toAttrs()
                ) {
                    Image(
                        modifier = Modifier.size(35.px),
                        src = Res.Icon.tick,
                        desc = "tick"
                    )
                }
            }
        }
        Box(modifier = Modifier.fillMaxWidth()) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(progressBarHeight)
                    .backgroundColor(Theme.LightGray.rgb)
            )
            Box(
                modifier = Modifier
                    .fillMaxWidth(percentage)
                    .height(progressBarHeight)
                    .backgroundColor(Theme.NYCTaxi.rgb)
                    .transition(
                        CSSTransition(
                            property = "width",
                            duration = 1000.ms,
                            delay = 100.ms * index
                        )
                    )
            )
        }
    }
}