package com.dawarchive.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.*
import com.dawarchive.components.sectionTitle
import com.dawarchive.components.screenShotCard
import com.dawarchive.models.ScreenShot
import com.dawarchive.models.Section
import com.dawarchive.models.Theme
import com.dawarchive.util.Constants.SECTION_WIDTH
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*

@Composable
fun screenShotSection() {
    Box(
        modifier = Modifier
            .id(Section.SCREENSHOTS.id)
            .maxWidth(SECTION_WIDTH.px)
            .padding(topBottom = 110.px),
        contentAlignment = Alignment.Center
    ) {
        screenShotContent()
    }
}


@Composable
fun screenShotContent() {
    val breakpoint = rememberBreakpoint()
    var selectedPage by remember { mutableStateOf(0) }

    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 100.percent
                else 90.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        sectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 65.px),
            section = Section.SCREENSHOTS,
            alignment = Alignment.CenterHorizontally
        )
        screenShotCards(
            breakpoint = breakpoint,
            selectedPage = selectedPage
        )
        screenShotNavigation(
            selectedPage = selectedPage,
            onNavigate = { index ->
                selectedPage = index
            }
        )

    }
}

@Composable
fun screenShotCards(
    breakpoint: Breakpoint,
    selectedPage: Int,
) {
    val screenShot1 = listOf(ScreenShot.EmptyList, ScreenShot.AddTrack, ScreenShot.Detail)
    val screenShot2 = listOf(ScreenShot.AddEdit1, ScreenShot.List, ScreenShot.DetailDark)

    SimpleGrid(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 90.percent
            else 100.percent
            )
            .margin(bottom = 8.px),
        numColumns = numColumns(base = 1, md = 2)
    ) {
        Box {
            screenShot1.forEach { screenShot ->
                screenShotCard(
                    modifier = Modifier
                        .margin(
                            right = if (breakpoint > Breakpoint.SM) 40.px else 0.px,
                            bottom = if (breakpoint > Breakpoint.MD) 0.px else 40.px
                        )
                        .visibility(
                            setVisibility(
                                selectedPage = selectedPage,
                                screenShot = screenShot
                            )
                        )
                        .opacity(
                            setOpacity(
                                selectedPage = selectedPage,
                                screenShot = screenShot
                            )
                        )
                        .transition(
                            CSSTransition(property = "visibility", duration = 300.ms),
                            CSSTransition(property = "opacity", duration = 300.ms)
                        ),
                    screenShot = screenShot,
                    breakpoint = breakpoint
                )
            }
        }


        Box {
            screenShot2.forEach { screenShot ->
                screenShotCard(
                    modifier = Modifier
                        .margin(
                            right = if (breakpoint > Breakpoint.SM) 40.px else 0.px,
                            bottom = if (breakpoint > Breakpoint.MD) 0.px else 40.px
                        )
                        .visibility(
                            setVisibility(
                                selectedPage = selectedPage,
                                screenShot = screenShot
                            )
                        )
                        .opacity(
                            setOpacity(
                                selectedPage = selectedPage,
                                screenShot = screenShot
                            )
                        )
                        .transition(
                            CSSTransition(property = "visibility", duration = 300.ms),
                            CSSTransition(property = "opacity", duration = 300.ms)
                        ),
                    screenShot = screenShot,
                    breakpoint = breakpoint
                )
            }
        }
    }
}


@Composable
fun screenShotNavigation(
    selectedPage: Int,
    onNavigate: (Int) -> Unit
) {
    val breakpoint = rememberBreakpoint()
    Row(
        modifier = Modifier
            .margin(top = if (breakpoint >= Breakpoint.LG) 100.px else 0.px)
            .fillMaxWidth(),
        horizontalArrangement = Arrangement.Center
    ) {
        repeat(3) { index ->
            Box(
                modifier = Modifier
                    .margin(right = 10.px)
                    .cursor(Cursor.Pointer)
                    .size(12.px)
                    .borderRadius(50.px)
                    .backgroundColor(
                        if (selectedPage == index) Theme.NYCTaxi.rgb
                        else Theme.LightGray.rgb
                    )
                    .onClick {
                        onNavigate(index)
                    }
            )
        }
    }
}


private fun setVisibility(
    selectedPage: Int,
    screenShot: ScreenShot
): Visibility {
    return when (screenShot) {
        ScreenShot.EmptyList -> {
            if (selectedPage == 0) Visibility.Visible else Visibility.Hidden
        }
        ScreenShot.AddEdit1 -> {
            if (selectedPage == 0) Visibility.Visible else Visibility.Hidden
        }
        ScreenShot.List -> {
            if (selectedPage == 1) Visibility.Visible else Visibility.Hidden
        }
        ScreenShot.AddTrack -> {
            if (selectedPage == 1) Visibility.Visible else Visibility.Hidden
        }
        ScreenShot.DetailDark -> {
            if (selectedPage == 2) Visibility.Visible else Visibility.Hidden
        }
        ScreenShot.Detail -> {
            if (selectedPage == 2) Visibility.Visible else Visibility.Hidden
        }
    }

}


private fun setOpacity(
    selectedPage: Int,
    screenShot: ScreenShot
): CSSSizeValue<CSSUnit.percent> {
    return when (screenShot) {
        ScreenShot.EmptyList -> {
            if (selectedPage == 0) 100.percent else 0.percent
        }
        ScreenShot.AddEdit1 -> {
            if (selectedPage == 0) 100.percent else 0.percent
        }
        ScreenShot.List -> {
            if (selectedPage == 1) 100.percent else 0.percent
        }
        ScreenShot.AddTrack -> {
            if (selectedPage == 1) 100.percent else 0.percent
        }
        ScreenShot.DetailDark -> {
            if (selectedPage == 2) 100.percent else 0.percent
        }
        ScreenShot.Detail -> {
            if (selectedPage == 2) 100.percent else 0.percent
        }
    }
}