package com.dawarchive.sections

import androidx.compose.runtime.Composable
import com.dawarchive.models.Section
import com.dawarchive.models.Theme
import com.dawarchive.util.Constants
import com.dawarchive.util.Constants.GOOGLE_PLAY_SERVICES
import com.dawarchive.util.Constants.PRIVACY_PARA_1
import com.dawarchive.util.Constants.PRIVACY_PARA_10
import com.dawarchive.util.Constants.PRIVACY_PARA_2
import com.dawarchive.util.Constants.PRIVACY_PARA_3
import com.dawarchive.util.Constants.PRIVACY_PARA_4
import com.dawarchive.util.Constants.PRIVACY_PARA_5
import com.dawarchive.util.Constants.PRIVACY_PARA_6
import com.dawarchive.util.Constants.PRIVACY_PARA_7
import com.dawarchive.util.Constants.PRIVACY_PARA_8
import com.dawarchive.util.Constants.PRIVACY_PARA_9
import com.dawarchive.util.Constants.PRIVACY_TITLE_1
import com.dawarchive.util.Constants.PRIVACY_TITLE_2
import com.dawarchive.util.Constants.PRIVACY_TITLE_3
import com.dawarchive.util.Constants.PRIVACY_TITLE_4
import com.dawarchive.util.Constants.PRIVACY_TITLE_5
import com.dawarchive.util.Constants.PRIVACY_TITLE_6
import com.dawarchive.util.Constants.PRIVACY_TITLE_7
import com.dawarchive.util.Constants.PRIVACY_TITLE_8
import com.dawarchive.util.Constants.PRIVACY_TITLE_9
import com.dawarchive.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun privacySection() {
    val breakpoint = rememberBreakpoint()

    Box(modifier = Modifier.fillMaxSize()) {
        privacyContent(breakpoint = breakpoint)
    }
}


@Composable
fun privacyContent(breakpoint: Breakpoint) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        privacyHeader()
        privacyTitle(breakpoint = breakpoint)
        privacyPolicy(breakpoint = breakpoint)
    }
}

@Composable
fun privacyHeader() {
    val ctx = rememberPageContext()
    Row(
        modifier = Modifier
            .padding(topBottom = 30.px),
    ) {
        navButton(
            onClick = {
                ctx.router.navigateTo("/#home")
            }
        ) {
            Image(
                modifier = Modifier
                    .clip(Circle())
                    .maxSize(55.px),
                src = Res.Icon.main_logo,
                desc = "DAWA Logo"
            )
        }
    }
}

@Composable
fun privacyTitle(breakpoint: Breakpoint) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(Theme.LighterGray.rgb)
            .padding(topBottom = 65.px),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        P(
            attrs = Modifier
                .margin(topBottom = 0.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(
                    if (breakpoint >= Breakpoint.MD) 36.px else
                        30.px
                )
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text(Section.PRIVACY.title)
        }
    }
}

@Composable
fun privacyPolicy(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .maxWidth(if (breakpoint >= Breakpoint.MD) 70.percent else 75.percent)
            .margin(top = 25.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_PARA_1)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_1)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))
        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_2)
        }
        Box(modifier = Modifier.minHeight(2.px))
        Link(
            path = Constants.GOOGLE_PLAY_SERVICES_URL,
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ){
            Text(GOOGLE_PLAY_SERVICES)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_2)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))
        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_3)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_3)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))
        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_4)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_4)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))
        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_5)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_5)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))

        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_6)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_6)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))

        P(
            attrs = Modifier
                
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_7)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_7)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))

        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_8)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Column(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalAlignment =
            Alignment.CenterHorizontally
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_8)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))

        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_9)
        }
        Box(modifier = Modifier.minHeight(25.px))
        Column(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalAlignment =
            Alignment.CenterHorizontally
        ) {
            P(
                attrs = Modifier
                    .toAttrs()
            ) {
                Text(PRIVACY_TITLE_9)
            }
        }
        Box(modifier = Modifier.minHeight(0.px))
        P(
            attrs = Modifier
                .toAttrs()
        ) {
            Text(PRIVACY_PARA_10)
        }
        Box(modifier = Modifier.minHeight(80.px))
    }
}