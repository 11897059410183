package com.dawarchive.models

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(hex = "#090580", rgb = rgb(r = 9, g = 5, b = 128)),
    Secondary(hex = "#45458C", rgb = rgb(r = 70, g = 69, b = 140)),
    Gray(hex = "#CFCFCF", rgb = rgb(r = 207, g = 207, b = 207)),
    LightGray(hex = "#EDEDED", rgb = rgb(r = 237, g = 237, b = 237)),
    LighterGray(hex = "#F9F9F9", rgb = rgb(r = 249, g = 249, b = 249)),
    Black(hex = "#1A0000", rgb = rgb(r = 26, g = 0, b = 0)),
    NYCTaxi(hex ="#f7b731", rgb = rgb(r= 247, g = 183, b = 49)),

    LimeGreen(hex = "#EBE76C", rgb = rgb(235, 231, 108)),
    BeigeBro(hex ="#FAF0D7", rgb = rgb(250, 240, 215))

}

enum class BenettonEffect(
    val letter: String,
    val hex: String,
    val rgb: CSSColorValue
) {
    D1(letter = "d",hex ="#f7b731", rgb = rgb(r= 247, g = 183, b = 49)),
    A2(letter = "a",hex = "#B5C99A", rgb = rgb(191, 201, 154)),
    W3(letter = "w",hex = "#FF9F29", rgb = rgb(r = 255, g = 159, b = 41 )),
    A4(letter = "a",hex = "#89C4E1", rgb = rgb(r = 137, g = 196 , b = 225)),
    R5(letter = "r",hex = "#749BC2", rgb = rgb(116, 155, 194)),
    C6(letter = "c",hex = "#59CE8F", rgb = rgb(89, 206, 143)),
    H7(letter = "h",hex = "#FF6D60", rgb = rgb(255, 109, 96)),
    I8(letter = "i",hex = "#EA906C", rgb = rgb(234, 144, 108)),
    V9(letter = "v",hex = "#CECE5A", rgb = rgb(206, 206, 90)),
    E10(letter = "e",hex = "#FD8D14", rgb = rgb(253, 141, 20)),

}