package com.dawarchive.sections

import androidx.compose.runtime.Composable
import com.dawarchive.models.BenettonEffect
import com.dawarchive.models.Section
import com.dawarchive.styles.MainLogoStyle
import com.dawarchive.styles.URLTitleStyle
import com.dawarchive.util.Constants
import com.dawarchive.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Composable
fun headerContent(onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()

    Row (
        modifier = Modifier
            .id(Section.HEADER.id)
            .fillMaxWidth(if (breakpoint > Breakpoint.MD) 75.percent else 90.percent)
            .margin(topBottom = 0.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ){
        leftSide(
            breakpoint = breakpoint,
            onMenuClicked = onMenuClicked
        )
        if (breakpoint > Breakpoint.SM) {
            rightSide()
        }
    }
}

@Composable
fun leftSide(
    breakpoint: Breakpoint,
    onMenuClicked: () -> Unit
) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        if (breakpoint <= Breakpoint.SM) {
            FaBars(
                modifier = Modifier
                    .margin(right = 15.px)
                    .margin(topBottom = 16.px)
                    .padding(topBottom = 16.px)
                    .onClick {
                        onMenuClicked()
                    },
                size = IconSize.XXL
            )
        }
        BenettonEffect.values().forEach { letters ->
           P(
               attrs = URLTitleStyle.toModifier()
                   .margin(topBottom = 0.px, leftRight = 0.px)
                   .fontFamily(Constants.FONT_FAMILY)
                   .fontSize(if(breakpoint > Breakpoint.SM) 35.px else 0.px)
                   .color(letters.rgb)
                   .toAttrs()
           ) {
               Text(letters.letter)
           }
        }
    }
}


@Composable
fun rightSide(
) {

    val ctx = rememberPageContext()

    Row (
        modifier = Modifier
            .padding(topBottom = 16.px),
        horizontalArrangement = Arrangement.End
    ){
        navButton(
            onClick = {
                ctx.router.navigateTo("/privacy")
            })
        {
            Image(
                modifier = NavButtonStyle
                    .toModifier()
                    .size(40.px),
                src = Res.Icon.privacy,
                desc = "Main Image"
            )
        }
    }
}


val NavButtonStyle by ComponentStyle.base {
    Modifier
        .margin(leftRight = 5.px)
        .padding(0.px)
        .size(40.px)
        .borderRadius(50.percent)
}

@Composable
fun navButton(
    onClick: () -> Unit,
    content: @Composable BoxScope.() -> Unit
) {
    Button(
        onClick = {
            onClick()
                  },
        NavButtonStyle.toModifier(),
        content = content
    )
}