package com.dawarchive.models

enum class Section(
    val id: String,
    val title: String,
    val path: String,
) {

    GOOGLE_PLAY(
        id = "googlePlayLogo",
        title = "Google Play",
        path = "#googlePlayLogo"
    ),
    PRIVACY(
        id = "privacyStatement",
        title = "Privacy",
        path = "#privacyStatement"
    ),
    HOME(
        id = "home",
        title = "Home",
        path = "#home"
    ),
    SCREENSHOTS(
        id = "screenShots",
        title = "Screen Shots",
        path = "#screenShot"
    ),

    UPDATES(
        id = "recentUpdates",
        title = "Recent Updates",
        path = "#updates"
    ),
    FOOTER(
        id = "footer",
        title = "Footer",
        path = "#footer"
    ),
    HEADER(
        id = "header",
        title = "Header",
        path = "#header"
    )


}