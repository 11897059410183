package com.dawarchive.pages

import androidx.compose.runtime.*
import com.dawarchive.components.backToTopButton
import com.dawarchive.components.overflowMenu
import com.dawarchive.models.Section
import com.dawarchive.sections.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page
    @Composable
    fun homePage() {

        var menuOpened by remember { mutableStateOf(false) }
        val cxt = rememberPageContext()


        // TODO ->  14 main content - Udemy

        Box(modifier = Modifier
            .id(Section.HOME.id)
            .fillMaxSize()
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                headerContent(onMenuClicked = { menuOpened = true })
                mainSection()
                screenShotSection()
                updatesSection()
                googlePlaySection()
                footerSection()
            }
            backToTopButton()
            if (menuOpened) {
                overflowMenu(onMenuClosed = { menuOpened = false })
            }
        }

}

