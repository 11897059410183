package com.dawarchive.models

import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.percent

enum class RecentUpdates(
    val title: String,
    val percentage: CSSSizeValue<CSSUnit.percent>
) {
    LANGUAGE_SUPPORT(
        title = "Language Support DE FR ES",
        percentage = 100.percent
    ),
    SEARCH_BAR(
        title = "Search Bar",
        percentage = 100.percent
    ),
    PREFERRED_ORDER(
        title = "Preferred Order Saved",
        percentage = 100.percent
    )
}