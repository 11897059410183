package com.dawarchive.styles

import com.dawarchive.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

val MainLogoStyle by ComponentStyle {
    base {
        Modifier
            .width(280.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
    hover {
        Modifier.width(320.px)
    }
}

val NavigationItemStyle by ComponentStyle {
    base {
        Modifier
            .color(Theme.Secondary.rgb)
            .transition(CSSTransition(property = "color", duration = 900.ms))
    }
    anyLink {
        Modifier.color(Theme.Secondary.rgb)
    }
    hover {
        Modifier.color(Theme.Black.rgb)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val URLTitleStyle by ComponentStyle {
    base {
        Modifier
            .transform { rotate(0.deg) }
            .transition(CSSTransition(property = "transform", duration = 200.ms))
    }
    hover {
        Modifier
            .transform { rotate((-25).deg) }
    }
}

//val UpdatesTextStyle by ComponentStyle {
//    base {
//        Modifier
//            .opacity(50.percent)
//            .transition(CSSTransition(property = "opacity", duration = 200.ms))
//    }
//    hover {
//        Modifier.opacity(100.percent)
//    }
//}

val ConfirmTickStyle by ComponentStyle {
    base {
        Modifier
            .opacity(50.percent)
            .color(Theme.LightGray.rgb)
            .transition(CSSTransition(property = TransitionProperty.All, duration = 200.ms))
    }

    hover {
        Modifier
            .opacity(100.percent)
            .color(Theme.NYCTaxi.rgb)
    }
}

//@OptIn(ExperimentalComposeWebApi::class)
//val UpdatesImageStyle by ComponentStyle {
//    base {
//        Modifier
//            .styleModifier { filter { grayscale(100.percent) } }
//            .borderRadius(r = 0.px)
//            .rotate(0.deg)
//            .transition(CSSTransition(property = TransitionProperty.All, duration = 200.ms))
//    }
//    hover {
//        Modifier
//            .styleModifier { filter { grayscale(0.percent) } }
//            .borderRadius(r = 100.px)
//            .rotate(10.deg)
//    }
//}