package com.dawarchive.models

import com.dawarchive.util.Constants.ADD_DETAIL
import com.dawarchive.util.Constants.ADD_DETAIL_DESC
import com.dawarchive.util.Constants.ADD_TRACK
import com.dawarchive.util.Constants.ADD_TRACK_DESC
import com.dawarchive.util.Constants.DARK
import com.dawarchive.util.Constants.DARK_DESC
import com.dawarchive.util.Constants.DETAIL
import com.dawarchive.util.Constants.DETAIL_DESC
import com.dawarchive.util.Constants.EMPTY_LIST
import com.dawarchive.util.Constants.EMPTY_LIST_DESC
import com.dawarchive.util.Constants.LIST
import com.dawarchive.util.Constants.LIST_DESC
import com.dawarchive.util.Res

enum class ScreenShot(
    val image: String,
    val title: String,
    val desc: String,
) {
    EmptyList(
        image = Res.Image.empty_list,
        title = EMPTY_LIST,
        desc = EMPTY_LIST_DESC
    ),
    AddEdit1(
        image = Res.Image.add_edit_1,
        title = ADD_TRACK,
        desc = ADD_TRACK_DESC
    ),
    AddTrack(
        image = Res.Image.add_track,
        title = ADD_DETAIL,
        desc = ADD_DETAIL_DESC
    ),
    List(
        image = Res.Image.list,
        title = LIST,
        desc = LIST_DESC
    ),
    Detail(
        image = Res.Image.detail,
        title = DETAIL,
        desc = DETAIL_DESC
    ),
    DetailDark(
        image = Res.Image.detail_dark,
        title = DARK,
        desc = DARK_DESC
    ),
}